<template>
  <v-container class="general">
    <page-title :component="'QuestionWizard'">
      <template v-slot:actions>
        <VideoTutorial
          v-if="activeStep !== 'done'"
          :component="'QuestionWizard'"
          :title="$t('questions-tutorial-title')"
        />
        <LanguageSwitcher
          ref="languageSwitcher"
          :events="true"
          @click="changeNext('stay')"
          :loading="isLoading"
        />
      </template>
    </page-title>
    <!-- Steps -->
    <AppStepperHeader
      v-model="stepper"
      :steps="steps"
      :loading="isLoading"
    />
    <v-window
      v-model="stepper"
      class="mb-5"
    >
      <v-window-item
        v-for="(step, i) in steps"
        :key="`content-${i}`"
        :value="i"
        :class="{ loading: isLoading }"
      >
        <component
          :is="step.component"
          :group-id="groupId"
          @attribute-created="listAttributes"
          @attribute-updated="listAttributes"
          @attribute-deleted="listAttributes"
        ></component>
      </v-window-item>
    </v-window>
    <!-- Actions -->
    <template v-if="activeStep !== 'done'">
      <v-btn
        round
        large
        class="ml-0 mr-3 white sw-accent text-none"
        @click="goToQuestionsList"
      >
        {{ $t("common.cancel") }}
      </v-btn>
      <v-btn
        round
        large
        class="ml-0 mr-3 sw-accent-bg sw-on-accent text-none"
        :loading="isLoading"
        @click="changeNext()"
      >
        {{ nextButtonText }}
      </v-btn>
    </template>

    <ConfirmationDialog ref="confirmationDialog" />
  </v-container>
</template>

<script>
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";
import AppStepperHeader from "@/components/AppStepperHeader.vue";
import moment from "moment-timezone";
import QuestionSettings from "@/components/QuestionWizard/QuestionSettings.vue";
import Questions from "@/components/QuestionWizard/Questions.vue";
import Menu from "@/components/QuestionWizard/Menu.vue";
import Message from "@/components/QuestionWizard/Message.vue";
import Receivers from "@/components/QuestionWizard/Receivers.vue";
import Done from "@/components/QuestionWizard/Done.vue";

export default {
  provide() {
    return {
      parentValidator: this.$validator,
    };
  },
  data: () => ({
    isLoading: false,
    stepper: 0,
    timeFormat: "YYYY-MM-DDTHH:mm:ssZ",
  }),
  computed: {
    groupId() {
      return this.$route.params.group_id;
    },
    groupPluginId() {
      return this.$route.params.group_plugin_id;
    },
    appContentLanguage() {
      return this.$store.getters.appContentLanguage;
    },
    plugin() {
      return this.$store.getters.questionWizardPlugin;
    },
    category() {
      return this.$store.getters.questionWizardCategory;
    },
    attributes() {
      return this.$store.getters.questionWizardAttributes;
    },
    ionicAppSidemenu: {
      get() {
        return this.$store.getters.questionWizardIonicAppSidemenu;
      },
      set(val) {
        this.$store.dispatch("setQuestionWizardIonicAppSidemenu", val);
      },
    },
    messageTemplate: {
      get() {
        return this.$store.getters.questionWizardMessageTemplate;
      },
      set(val) {
        this.$store.dispatch("setQuestionWizardMessageTemplate", val);
      },
    },
    steps() {
      return [
        {
          name: "settings",
          title: this.$t("question_wizard.question_settings"),
          component: QuestionSettings,
          enabled: true,
        },
        {
          name: "questions",
          title: this.$t("question_wizard.questions"),
          component: Questions,
          enabled: true,
        },
        {
          name: "menu",
          title: this.$t("question_wizard.menu"),
          component: Menu,
          enabled:
            this.plugin.questionwizard_destination_enabled &&
            this.ionicAppSidemenu,
        },
        {
          name: "message",
          title: this.$t("question_wizard.message"),
          component: Message,
          enabled: this.plugin.questionwizard_push_message_enabled,
        },
        {
          name: "receivers",
          title: this.$t("question_wizard.receivers"),
          component: Receivers,
          enabled: this.plugin.questionwizard_push_message_enabled,
        },
        {
          name: "done",
          title: this.$t("question_wizard.done"),
          component: Done,
          enabled: true,
        },
      ].filter((el) => el.enabled);
    },
    activeStep() {
      return this.steps[this.stepper].name;
    },
    nextButtonText() {
      if (this.steps.length - this.stepper !== 2) {
        return this.$t("common.continue");
      }

      return this.$t("common.save");
    },
  },
  components: {
    LanguageSwitcher,
    AppStepperHeader,
  },
  watch: {
    groupPluginId: {
      immediate: true,
      handler() {
        this.fetchAll();
      },
    },
    appContentLanguage: {
      handler(newVal, oldVal) {
        if (newVal === oldVal) return;

        this.fetchAll();
      },
    },
    activeStep: {
      immediate: true,
      handler() {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      },
    },
  },
  mounted() {
    this.stepper = 0;
  },
  methods: {
    stepClass(i) {
      return [
        "pa-3 text-center step",
        {
          complete: i < this.stepper,
          editable: i < this.stepper && this.activeStep !== "done",
          active: i === this.stepper,
        },
      ];
    },
    async changeNext(action) {
      this.errors.clear();

      if (this.activeStep === "settings") {
        const isValid = await this.$validator.validateAll("settings");

        if (!isValid) return;

        try {
          this.isLoading = true;

          if (!this.plugin.id) {
            await this.createPlugin();
            await this.createCategory();

            this.$router.push({
              name: "questions_edit",
              params: {
                group_id: this.groupId,
                group_plugin_id: this.plugin.id,
              },
            });
          } else {
            await this.updatePlugin();

            if (!this.category.id) {
              await this.createCategory();
            } else {
              await this.updateCategory();
            }
          }

          await this.updateMenu();

          if (this.messageTemplate.id) {
            await this.updateMessageTemplate();
          }

          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;

          if (error) {
            this.errorMessageShow(error);
            return;
          }
        }
      }

      if (this.activeStep === "questions") {
        if (!this.attributes.length) {
          this.errors.add({
            msg: this.$t("question_wizard.no_questions_error"),
            scope: "questions",
            field: "questions",
          });

          return;
        }
      }

      if (this.activeStep === "menu") {
        const isValid = await this.$validator.validateAll("menu");

        if (!isValid) return;

        try {
          this.isLoading = true;

          await this.updateMenu();

          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;

          if (error) {
            this.errorMessageShow(error);
            return;
          }
        }
      }

      if (this.activeStep === "message") {
        const isValid = await this.$validator.validateAll("message");

        if (!isValid) return;

        if (!this.messageTemplate.body) {
          this.errors.add({
            msg: "Message body field is required",
            scope: "message-template",
            field: "body",
          });

          return;
        }

        try {
          this.isLoading = true;

          if (!this.messageTemplate.id) {
            await this.createMessageTemplate();
          } else {
            await this.updateMessageTemplate();
          }

          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;

          if (error) {
            this.errorMessageShow(error);
            return;
          }
        }
      }

      if (this.activeStep === "receivers") {
        const isValid = await this.$validator.validateAll("receivers");

        if (!isValid) return;

        try {
          this.isLoading = true;

          await this.updateMessageTemplate();

          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;

          if (error) {
            this.errorMessageShow(error);
            return;
          }
        }
      }

      if (action !== "stay") {
        this.stepper += 1;
        return;
      }

      this.$refs.languageSwitcher.open();
    },
    goToQuestionsList() {
      this.$router
        .push({
          name: "questions",
        })
        .catch(() => {});
    },
    transformDate(date) {
      if (!date) return null;
      return moment.tz(date, this.userTimezone).format(this.timeFormat);
    },
    transformPluginForSave(plugin) {
      return {
        name: plugin.name,
        questionwizard_introduction_banner:
          plugin.questionwizard_introduction_banner
            ? plugin.questionwizard_introduction_banner.id
            : null,
        questionwizard_introduction_headline:
          plugin.questionwizard_introduction_headline,
        questionwizard_introduction_content:
          plugin.questionwizard_introduction_content,
        questionwizard_thank_you_banner: plugin.questionwizard_thank_you_banner
          ? plugin.questionwizard_thank_you_banner.id
          : null,
        questionwizard_thank_you_headline:
          plugin.questionwizard_thank_you_headline,
        questionwizard_thank_you_content:
          plugin.questionwizard_thank_you_content,
        questionwizard_start_button: plugin.questionwizard_start_button,
        questionwizard_finish_button: plugin.questionwizard_finish_button,
        questionwizard_active_from: plugin.questionwizard_active_from,
        questionwizard_active_till: plugin.questionwizard_active_till,
        questionwizard_closed_content: plugin.questionwizard_closed_content,
        questionwizard_destination_enabled:
          plugin.questionwizard_destination_enabled ? 1 : 0,
        questionwizard_push_message_enabled:
          plugin.questionwizard_push_message_enabled ? 1 : 0,
      };
    },
    transformPluginForEdit(plugin = {}) {
      return {
        id: plugin.id,
        name: plugin.name,
        questionwizard_introduction_banner: plugin.attributes
          ? plugin.attributes.questionwizard_introduction_banner
          : null,
        questionwizard_introduction_headline: plugin.attributes
          ? plugin.attributes.questionwizard_introduction_headline
          : null,
        questionwizard_introduction_content: plugin.attributes
          ? plugin.attributes.questionwizard_introduction_content
          : null,
        questionwizard_thank_you_banner: plugin.attributes
          ? plugin.attributes.questionwizard_thank_you_banner
          : null,
        questionwizard_thank_you_headline: plugin.attributes
          ? plugin.attributes.questionwizard_thank_you_headline
          : null,
        questionwizard_thank_you_content: plugin.attributes
          ? plugin.attributes.questionwizard_thank_you_content
          : null,
        questionwizard_start_button: plugin.attributes
          ? plugin.attributes.questionwizard_start_button
          : null,
        questionwizard_finish_button: plugin.attributes
          ? plugin.attributes.questionwizard_finish_button
          : null,
        questionwizard_active_from: plugin.attributes
          ? plugin.attributes.questionwizard_active_from
          : null,
        questionwizard_active_till: plugin.attributes
          ? plugin.attributes.questionwizard_active_till
          : null,
        questionwizard_closed_content: plugin.attributes
          ? plugin.attributes.questionwizard_closed_content
          : null,
        questionwizard_destination_enabled: plugin.attributes
          ? plugin.attributes.questionwizard_destination_enabled
          : false,
        questionwizard_push_message_enabled: plugin.attributes
          ? plugin.attributes.questionwizard_push_message_enabled
          : false,
      };
    },
    async fetchAll() {
      try {
        this.isLoading = true;

        if (this.groupPluginId) {
          await this.getPlugin();
          await this.getCategory();
          await this.listAttributes();
          await this.getMessageTemplate();
        } else {
          this.messageTemplate = {
            body: "",
          };

          this.$store.dispatch("setQuestionWizardPlugin", {
            questionwizard_active_from: null,
            questionwizard_active_till: null,
          });
          this.$store.dispatch("setQuestionWizardCategory", {});
          this.$store.dispatch("setQuestionWizardAttributes", []);
        }

        await this.listGroupMenus();

        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;

        if (error) {
          this.errorMessageShow(error);
        }
      }
    },
    async createPlugin() {
      const specs = [
        this.groupId,
        {
          ...this.transformPluginForSave(this.plugin),
          questionwizard_introduction_icon: "fas question",
          questionwizard_thank_you_icon: "fas question",
          questionwizard_only_new: 0,
          prefix: "questionwizard",
          with_attributes: 1,
          expand_attributes: 1,
          lang: this.appContentLanguage,
        },
      ];

      const response = await this.$api.groupPlugins.create(...specs);

      if (!response) return;

      await this.$store.dispatch("setQuestionWizardPlugin", response.data.data);
    },
    async getPlugin() {
      const params = [
        this.groupId,
        this.groupPluginId,
        {
          with_attributes: 1,
          expand_attributes: 1,
          lang: this.appContentLanguage,
        },
      ];

      const response = await this.$api.groupPlugins.get(...params);

      await this.$store.dispatch(
        "setQuestionWizardPlugin",
        this.transformPluginForEdit(response.data.data),
      );
    },
    async updatePlugin() {
      const specs = [
        this.groupId,
        this.plugin.id,
        {
          ...this.transformPluginForSave(this.plugin),
          with_attributes: 1,
          expand_attributes: 1,
          lang: this.appContentLanguage,
        },
      ];

      const response = await this.$api.groupPlugins.update(...specs);

      if (!response) return;

      await this.$store.dispatch(
        "setQuestionWizardPlugin",
        this.transformPluginForEdit(response.data.data),
      );
    },
    async createCategory() {
      const specs = [
        this.groupId,
        {
          name: this.plugin.name,
          group_plugin_id: this.plugin.id,
          lang: this.appContentLanguage,
        },
      ];

      const response = await this.$api.groupUserAttributeCategories.create(
        ...specs,
      );

      if (!response) return;

      await this.$store.dispatch(
        "setQuestionWizardCategory",
        response.data.data,
      );
    },
    async getCategory() {
      const params = [
        this.groupId,
        {
          group_plugin_id: this.plugin.id,
          per_page: 1,
          lang: this.appContentLanguage,
        },
      ];

      const response = await this.$api.groupUserAttributeCategories.list(
        ...params,
      );

      if (!response) return;

      await this.$store.dispatch(
        "setQuestionWizardCategory",
        response.data.data[0],
      );
    },
    async updateCategory() {
      const specs = [
        this.groupId,
        this.category.id,
        {
          name: this.plugin.name,
          lang: this.appContentLanguage,
        },
      ];

      await this.$api.groupUserAttributeCategories.update(...specs);
    },
    async listGroupMenus() {
      const params = [
        this.groupId,
        { with_disabled: 1, lang: this.appContentLanguage },
      ];

      const response = await this.$api.groupMenus.list(...params);

      const ionicAppSidemenu = response.data.data.find(
        (el) => el.slug === "ionic-app-sidemenu",
      );

      const destination = ionicAppSidemenu.destinations.find(
        (el) =>
          el.component === "QuestionPage" &&
          el.group_plugin_id == this.groupPluginId,
      );

      if (!destination || !destination.id) {
        ionicAppSidemenu.destinations.push({
          editable: true,
          name: this.$t("question_wizard.ionic_app_sidemenu_destination_name"),
          description: "",
          icon: "fas question",
          is_public: false,
          enabled: 1,
          promoted: 0,
        });
      } else {
        destination.editable = true;
      }

      this.ionicAppSidemenu = ionicAppSidemenu;
    },
    async updateMenu() {
      const destinations = this.ionicAppSidemenu.destinations.map((el, i) => {
        if (el.editable) {
          return {
            id: el.id,
            name: el.name || this.plugin.name,
            description: el.description,
            component: "QuestionPage",
            group_plugin_id: this.plugin.id,
            is_public: el.is_public,
            icon: el.icon,
            sort_order: i,
            promoted: el.promoted ? 1 : 0,
            enabled: this.plugin.questionwizard_destination_enabled ? 1 : 0,
          };
        }

        return {
          id: el.id,
          sort_order: i,
        };
      });

      const specs = [
        this.groupId,
        this.ionicAppSidemenu.id,
        {
          destinations: destinations,
          lang: this.appContentLanguage,
        },
      ];

      await this.$api.groupMenus.update(...specs);
      await this.listGroupMenus();
    },
    async createMessageTemplate() {
      const specs = [
        this.groupId,
        {
          name: this.messageTemplate.subject,
          subject: this.messageTemplate.subject,
          body: this.messageTemplate.body,
          message_image: this.messageTemplate.message_image
            ? this.messageTemplate.message_image.id
            : null,
          audiences: this.messageTemplate.audiences
            ? this.messageTemplate.audiences.map(({ id }) => id).toString()
            : null,
          recipients: this.messageTemplate.recipients
            ? this.messageTemplate.recipients.map(({ id }) => id).toString()
            : null,
          send_time_start: this.transformDate(
            this.messageTemplate.send_time_start,
          ),
          send_time_end: this.transformDate(this.messageTemplate.send_time_end),
          type: "push",
          destinations: [
            {
              name: this.plugin.name,
              component: "QuestionPage",
              group_plugin_id: this.plugin.id,
              enabled: 1,
            },
          ],
          group_plugin_id: this.plugin.id,
          with_attributes: 1,
          active: this.messageTemplate.active ? 1 : 0,
          lang: this.appContentLanguage,
        },
      ];

      const response = await this.$api.groupMessageTemplates.create(...specs);

      const messageTemplate = response.data.data;

      if (messageTemplate.message_image) {
        try {
          const mediaGetResponse = await this.$api.media.get(
            messageTemplate.message_image,
          );

          messageTemplate.message_image = mediaGetResponse.data.data;
        } catch (error) {
          if (error) {
            messageTemplate.message_image = null;
          }
        }
      }

      this.messageTemplate = messageTemplate;
    },
    async getMessageTemplate() {
      const params = [
        this.groupId,
        {
          group_plugin_id: this.plugin.id,
          with_attributes: 1,
          per_page: 1,
          lang: this.appContentLanguage,
        },
      ];

      const response = await this.$api.groupMessageTemplates.list(...params);

      if (!response) return;

      const messageTemplate = response.data.data[0];

      if (!messageTemplate || !messageTemplate.id) return;

      if (messageTemplate.message_image) {
        try {
          const mediaGetResponse = await this.$api.media.get(
            messageTemplate.message_image,
          );

          messageTemplate.message_image = mediaGetResponse.data.data;
        } catch (error) {
          if (error) {
            messageTemplate.message_image = null;
          }
        }
      }

      this.messageTemplate = messageTemplate;
    },
    async updateMessageTemplate() {
      let active = this.messageTemplate.active ? 1 : 0;

      if (!this.plugin.questionwizard_push_message_enabled) {
        active = 0;
        this.messageTemplate.active = 0;
      }

      const params = [
        this.groupId,
        this.messageTemplate.id,
        {
          name: this.messageTemplate.subject,
          subject: this.messageTemplate.subject,
          body: this.messageTemplate.body,
          message_image:
            this.messageTemplate.message_image &&
            this.messageTemplate.message_image.id
              ? this.messageTemplate.message_image.id
              : null,
          audiences: this.messageTemplate.audiences
            ? this.messageTemplate.audiences.map(({ id }) => id).toString()
            : null,
          recipients: this.messageTemplate.recipients
            ? this.messageTemplate.recipients.map(({ id }) => id).toString()
            : null,
          send_time_start: this.transformDate(
            this.messageTemplate.send_time_start,
          ),
          send_time_end: this.transformDate(this.messageTemplate.send_time_end),
          destinations: [
            {
              name: this.plugin.name,
              component: "QuestionPage",
              group_plugin_id: this.plugin.id,
              enabled: 1,
            },
          ],
          active: active,
          lang: this.appContentLanguage,
        },
      ];

      await this.$api.groupMessageTemplates.update(...params);
    },
    async listAttributes() {
      this.errors.clear();

      const params = [
        this.groupId,
        {
          group_plugin_id: this.plugin.id,
          per_page: 100,
          lang: this.appContentLanguage,
        },
      ];

      const response = await this.$api.groupUserAttributes.list(...params);

      if (!response) return;

      const attributes = response.data.data.filter((el) => el.enabled);

      await this.$store.dispatch("setQuestionWizardAttributes", attributes);
    },
  },
};
</script>

<style lang="scss" scoped>
.loading {
  opacity: 0.4;
  transition: all 0.2s;
  pointer-events: none;
}
</style>
